.comment-tab {
  p {
    img {
      max-width: 1100px;
      height: auto;

      @media (max-width: 1440px) {
          max-width: 980px;
      }
      @media (max-width: 1200px) {
        max-width: 750px;
      }
      @media (max-width: 768px) {
        max-width: 500px;
      }
      @media (max-width: 500px) {
        max-width: 320px;
      }
    }
  }
}
