.form-select-formik {
  .formik-drop__control {
    border: 1px solid #e6e6e7;
    background-color: white;
    padding: 0px 5px;
  }
}

.overdue-alert {
  margin-top: -30px;
  margin-bottom: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.crop-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
