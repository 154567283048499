button.btn-green, a.btn-green, button.btn-green-simple, a.btn-green-simple{
    background-color: $sempai-green;
    text-transform: lowercase;
    color: $white;
    &:hover{
        background-color: $sempai-green-hover;
        color: $white;
    }
}

button.btn-yellow {
    background-color: $yellow;
    border-radius: 50px;
}

button.btn-green, a.btn-green {
    border-radius: 50px;
}
button.btn-green-simple, a.btn-green-simple{
    border-radius: 5px 5px 0 0 ;
}

.accordion-custom-btn{
    top: 0;
    left: -15px;
    width: 110%;
    display: flex;
    position: absolute;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 100px;
    padding-left: 30px;
    box-shadow: 2px 3px 39px -10px rgba(183, 183, 183, 1);
    background-color: $white;
    @include media-breakpoint-up(sm){
        left: -30px;
    }
    @include media-breakpoint-up(xxl){
        left: -200px;
        width: 140%;
        padding-left: 230px;
    }
}