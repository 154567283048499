//
// SVG Icon
//
@mixin svg-icon-stroke-color($color, $important: false) {
    svg {
        [stroke]:not(.permanent):not(g) {
            transition: fill 0.3s ease;
            stroke: $color valueif($important, !important, null);
            fill: none
        }

        &:hover {
            [stroke]:not(.permanent):not(g) {
                transition: fill 0.3s ease;
            }
        }
    }
}

@mixin svg-icon-color($color, $important: false) {
    svg {
        [fill]:not(.permanent):not(g) {
            transition: fill 0.3s ease;
            fill: $color valueif($important, !important, null);
        }

        &:hover {
            [fill]:not(.permanent):not(g) {
                transition: fill 0.3s ease;
            }
        }
    }
}

@mixin svg-icon-transition() {
    svg {
        [fill]:not(g) {
            transition: fill 0.3s ease;
        }
    }
}

@mixin svg-icon-size($size, $important: false) {
    svg {
        height: $size valueif($important, !important, null);
        width: $size valueif($important, !important, null);
    }
}
