.action-btn {
  cursor: pointer;
}

.transform-rotate {
  transform: rotate(0.25turn);
}

.card-custom{
  .card-header{
    border-bottom: none;
  }
}
.tab-content{
  border-radius: 10px;
}
.nav-tabs-report {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding-bottom: 20px !important;
  @include media-breakpoint-up(xl) {
    padding-bottom: 0 !important;
  }
  .nav-item {
    .nav-link-report {
      border-radius: 8px 8px 0 0;
      border-bottom: none;
      font-weight: bolder;
      color: $gray-500;
      &:hover {
        color: $dark;
      }
    }
    .nav-link-report-small{
      border-radius: 8px 8px 0 0;
      border-bottom: none;
      font-weight: bolder;
      color: $gray-500;
      &:hover {
        color: $dark;
      }
    }
    .nav-link-report.active {
      border: 3px solid $gray-300;
      border-bottom: none;
      font-weight: bolder;
      color: $dark;
      &:hover {
        color: $dark;
        border: 3px solid $gray-300;
        border-bottom: none;
      }
    }
    .nav-link-report-small.active {
      border: 2px solid $gray-300;
      border-bottom: none;
      font-weight: bolder;
      color: $dark;
      &:hover {
        color: $dark;
        border: 2px solid $gray-300;
        border-bottom: none;
      }
    }
  }
}


