.report-to-pdf {
  @media print {
    margin: 10mm;
    .card-body {
      background-color: white !important;
    }

    .page-slice {
      page-break-after: always;
    }

    .fb-summary {
      display: flex !important;
      flex-direction: row !important;
      &-stat-title {
        font-size: 25px !important;
      }
    }

    .conversion-data {
      &-info {
        flex-direction: row !important;
      }
    }

    .clicks-group-report_title {
      padding-bottom: 5px !important;
    }

    .clicks-group-chart {
      min-height: 25px !important;
    }

    .social-group {
      flex-direction: row !important;
      #chartdiv {
        width: 110% !important;
      }
    }

    .device-data {
      flex-direction: row !important;
      padding-bottom: 5px;
    }

    .device-charts {
      flex-direction: row !important;
    }

    .fb-campaign-array {
      &-title-text {
        font-size: 12px !important;
        th {
          min-width: 50px !important;
        }
      }
      &-text {
        font-size: 10px !important;
      }
    }

    .conversion-report {
      margin-top: 10mm !important;
    }

    .statistic-equals {
      margin-top: 15mm !important;
    }

    .conversion-report-summary {
      flex-direction: row !important;
      &-value {
        font-size: 18px !important;
      }
      &-title {
        font-size: 14px !important;
      }
    }

    .session-group {
      &-main-value {
        flex-direction: row !important;
      }

      &-chart {
        padding-left: 0 !important;
        margin-right: 20px;
      }
    }

    .time-statistic{
      margin-top: 10mm;
      &-widget{
        width: 25% !important;
      }
    }

    .sem-basic-data{
      &-widget {
        width: 25% !important;
      }
    }

    .compare-data{
      &-widget{
        width: 25% !important;
      }
      &-year{
        margin-top: 10mm;
      }
    }
  }
}

.print-btn {
  position: absolute;
  right: 15px;
}




.print_popup{
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background: white !important;
  z-index : 15 !important;
}

.popup-container {
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 1220px;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0, 0, 0, 0.5);
}
.popup-body {
  position: absolute;
  margin: 0 auto;
  width: 1220px;
  top: 5%;
  //text-align: center;
  border-radius: 15px;
  border-color: black;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.text-left{
  text-align: left;
}
.print_overlay{
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  background: rgb(255 255 255) !important;
  z-index: 120 !important;
}