.pagination-custom {
  .page-item {
    background-color: $white;
    &.active {
      .page-link {
        border: 1px solid #e6e6e7;
        border-radius: 5px;
        background-color: $white;
        color: $pagination-color !important;
      }
    }
    .page-link {
      &:hover {
        border: 1px solid #e6e6e7;
        border-radius: 5px;
        background-color: $white;
        color: $pagination-color !important;
      }
    }
  }
}
